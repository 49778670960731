<template>
  <v-container class="page-login" fill-height>
    <v-row>
      <v-col>
        <v-card class="pa-3 page-login__card" tile>
          <v-card-title>
            <img src="/static/m.png" alt="มูลนิธิแม่ฟ้าหลวง" width="55" />
            <h1 class="primary--text display-1">
              มูลนิธิแม่ฟ้าหลวง
            </h1>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              class="my-10"
              lazy-validation
              v-model="formValid"
            >
              <v-text-field
                append-icon="person"
                autocomplete="off"
                name="login"
                label="Login"
                placeholder="Username or Email"
                type="text"
                required
                :rules="formRule.username"
                v-model="fromModel.username"
                :error-messages="errorMessage"
                @keyup.enter.native="login"
              />
              <v-text-field
                append-icon="lock"
                autocomplete="off"
                name="password"
                label="Password"
                placeholder="Password"
                type="password"
                :rules="formRule.password"
                required
                v-model="fromModel.password"
                @keyup.enter.native="login"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <!-- <v-icon
              class="mr-3"
              v-text="item"
              v-for="(item, key) in socialIcons"
              :key="key"
              @click="handleSocialLogin"
            /> -->
            <v-spacer />
            <v-btn large tile color="primary" @click="login" :loading="loading">
              Login
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { login } from '@/api/auth'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'PageLogin',
  data() {
    return {
      errorMessage: '',
      loading: false,
      formValid: false,
      fromModel: {
        username: '',
        password: ''
      },
      formRule: {
        username: [(v) => !!v || 'Username is required'],
        password: [(v) => !!v || 'Password is required']
      },
      socialIcons: ['mdi-google', 'mdi-twitter', 'mdi-facebook']
    }
  },
  computed: {
    ...mapGetters({
      isLogin: 'isLogin'
    }),

    errorMsg() {
      return this.error
        ? ['Error', 'Another one', 'One more', 'All the errors']
        : []
    },
    prefix() {
      return ''
    }
  },
  mounted() {
    if (this.isLogin) {
      this.$router.push('/dashboard')
    }
  },
  methods: {
    ...mapActions(['setLoginUser']),
    login() {
      if (this.$refs.form.validate()) {
        console.log('validate success')
        this.loading = true
        login(this.fromModel.username, this.fromModel.password)
          .then((user) => {
            console.log('login success')
            this.errorMessage = []
            this.setLoginUser(user)
            this.$router.push('/dashboard')
            this.loading = false
          })
          .catch(() => {
            this.errorMessage = ['Username or password is incorrect']
            this.loading = false
          })
      }
    },
    handleSocialLogin() {}
  }
}
</script>

<style lang="sass" scoped>
.page-login
  &__card
    max-width: 600px
    margin: 0 auto
</style>
